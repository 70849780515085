<template>
	<div class="frameCon">
		<lwa></lwa>
	</div>
</template>

<script>
	import lwa from '@/components/Amazon/components/lwa.vue';
	export default {
	  name: "login",
	  components: {
	    lwa
	  },
		}
</script>

<style>
</style>
