<template>
	<div style="padding: 20px;text-align: center;">
		<!-- <span id="LoginWithAmazon" @click="LoginWithAmazon">
			<img border="0" alt="Login with Amazon" src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png" width="156" height="32" />
		</span>
		<div id="amazon-root"></div> -->

		<div style="padding: 20px 10px; font-size: 16px;">
			
			<div v-if="!authSrcStep2" class="step-con">
				<div class="step-title">STEP1</div>
				
				<div class="step-msg">正在打开授权页面,请稍候...</div>
				
				<div class="step-loading-icon" style=""><i class="el-icon-loading"></i></div>
				
				<div class="auth-icon-con">
					<div class="auth-icon-az">
						<img src="../../../assets/images/amazon/800px-Amazon_logo.png" width="150" height="auto" />
					</div>
					<div class="auth-icon-arrow">
						<img src="../../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
					</div>
					<div class="auth-icon-hytx">
						<img src="../../../assets/logo_en.png" width="150" height="auto" />
					</div>
				</div>
				
				<!-- <a target="_blank" :href="authSrc">{{$t('i18nn_907aa9302190b8b6')}}</a> -->
			</div>
			

			<div v-if="authSrcStep2" class="step-con">
				<div class="step-title">STEP2</div>
				
				<div class="step-msg">{{$t('i18nn_67c2289162532917')}}</div>
				
				<div class="step-loading-icon" style=""><i class="el-icon-loading"></i></div>
				
				<div class="auth-icon-con">
					<div class="auth-icon-az">
						<img src="../../../assets/images/amazon/800px-Amazon_logo.png" width="150" height="auto" />
					</div>
					<div class="auth-icon-arrow">
						<img src="../../../assets/images/amazon/arrow-bothway.png" width="30" height="auto" />
					</div>
					<div class="auth-icon-hytx">
						<img src="../../../assets/logo_en.png" width="150" height="auto" />
					</div>
				</div>
				
				
				<!-- <a v-if="authSrcStep2" target="_self" :href="authSrcStep2">
					{{authSrcStep2}}
				</a> -->
				<!-- <p>{{ authSrcStep2 }}</p> -->
				<!-- <p>3秒后自动跳转..</p> -->
			</div>
		</div>
		<!-- https://test.warehouse.hytx.com/#/loginFrame/AmazonAuth?
		amazon_callback_uri=https%3A%2F%2Fsellercentral.amazon.com%2Fapps%2Fauthorize%2Fconfirm%2Famzn1.sp.solution.fe68b822-dca8-4b16-b19a-ddab7c62da97
		&amazon_state=MTYyNjA0OTU1MzcwMG_vv71FVO-_vWMD77-9czAl77-977-9GDcV77-9T3ogE1nskpRy77-9O0Ie77-9a--_vSXIhgPvv713KBsb77-9JiHvv70zZBbvv71I77-9KXzvv71J77-90adC77-95YSn
		&version=beta
		&selling_partner_id=A3159RPMOGUJPQ -->
	</div>
</template>

<script>
//线上
// const application_id = 'amzn1.sp.solution.c6dadbbc-9d5d-4d3a-a3da-836584ef1978';
//线上-新
// const application_id = 'amzn1.sp.solution.8d682a64-a4bf-45fa-8c2c-b0b851e984f3';
// const redirect_uri_base = 'https://warehouse.hytx.com';

//测试
// const application_id = 'amzn1.sp.solution.fe68b822-dca8-4b16-b19a-ddab7c62da97';
//测试新
const application_id = 'amzn1.sp.solution.560ca8c7-2a15-471e-b7c1-c5042f0354e2';
const redirect_uri_base = 'https://test.warehouse.hytx.com';

export default {
	data() {
		return {
			authSrc: `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${application_id}&version=beta`,

			authSrcStep2: ''
		};
	},
	mounted() {
		this.step2Redirect();

		// this.$nextTick(()=>{
		// 	this.init();
		// })
	},
	methods: {
		step2Redirect() {
			if (this.$route.query.amazon_callback_uri) {
				let amazon_callback_uri = decodeURIComponent(this.$route.query.amazon_callback_uri);
				let version = this.$route.query.version;
				let amazon_state = this.$route.query.amazon_state;
				// let selling_partner_id = this.$route.query.selling_partner_id;
				let state = new Date().getTime();
				// let random = (Math.random()*100000).toFixed(0);
				// let redirect_uri = encodeURIComponent(`${redirect_uri_base}/#/loginFrame/AmazonAuthList`);
				let redirect_uri = encodeURIComponent(`${redirect_uri_base}/AmazonFrame/AmazonAuthList`);
				// let redirect_uri = `${redirect_uri_base}/#/loginFrame/AmazonAuthList`;
				// let redirect_uri = encodeURIComponent("https://test.warehouse.hytx.com");
				// let redirect_uri = encodeURIComponent("https://test.warehouse.hytx.com/#/loginFrame/login");
				// let redirect_uri = "https://test.warehouse.hytx.com/#/loginFrame/login";
				// let redirect_uri = "https://test.warehouse.hytx.com/#/loginFrame/AmazonAuth";
				// let redirect_uri = "https://test.warehouse.hytx.com";
				// window.location.href = `${amazon_callback_]uri}?amazon_state=${amazon_state}&state=${state}&version=${version}`;
				this.authSrcStep2 = `${amazon_callback_uri}?redirect_uri=${redirect_uri}&amazon_state=${amazon_state}&state=${state}&version=${version}`;
				// this.authSrcStep2 = `${amazon_callback_uri}?redirect_uri=${redirect_uri}&amazon_state=${amazon_state}&version=${version}`;
				// setTimeout(() => {
					window.location.href = this.authSrcStep2;
				// }, 3000);
			} else {
				window.location.href = this.authSrc;
			}
		}
		// init(){

		// 	window.onAmazonLoginReady = function() {
		// 		amazon.Login.setClientId('amzn1.sp.solution.fe68b822-dca8-4b16-b19a-ddab7c62da97');
		// 	};
		// 	(function(d) {
		// 		var a = d.createElement('script');
		// 		a.type = 'text/javascript';
		// 		a.async = true;
		// 		a.id = 'amazon-login-sdk';
		// 		a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
		// 		d.getElementById('amazon-root').appendChild(a);
		// 	})(document);

		// 	window.doLogin = function() {
		// 	        let options = {};
		// 	        options.scope = 'profile';
		// 	        options.pkce = true;
		// 	        amazon.Login.authorize(options, function(response) {
		// 	            if ( response.error ) {
		// 	                alert('oauth error ' + response.error);
		// 	            return;
		// 	            }
		// 	            amazon.Login.retrieveToken(response.code, function(response) {
		// 	                if ( response.error ) {
		// 	                    alert('oauth error ' + response.error);
		// 	                return;
		// 	                }
		// 	                amazon.Login.retrieveProfile(response.access_token, function(response) {
		// 	                    alert('Hello, ' + response.profile.Name);
		// 	                    alert('Your e-mail address is ' + response.profile.PrimaryEmail);
		// 	                    alert('Your unique ID is ' + response.profile.CustomerId);
		// 	                    if ( window.console && window.console.log )
		// 	                       window.console.log(response);
		// 	                });
		// 	            });
		// 	        });
		// 	   };
		// 	// document.getElementById('LoginWithAmazon').onclick = function() {
		// 	// 	options = {};
		// 	// 	options.scope = 'profile';
		// 	// 	options.scope_data = {
		// 	// 		profile: { essential: false }
		// 	// 	};
		// 	// 	amazon.Login.authorize(options, 'https://www.example.com/handle_login.php');
		// 	// 	return false;
		// 	// };
		// },
		// LoginWithAmazon(){
		// 	let  options = {};
		// 	options.scope = 'profile';
		// 	options.scope_data = {
		// 		profile: { essential: false }
		// 	};

		// 	amazon.Login.authorize(options, 'https://test.warehouse.hytx.com/#/loginFrame/login');
		// 	return false;
		// }
	}
};
</script>

<style lang="less" scoped>
	.step-con {
		line-height: 150%;
	}
	.step-title {
		font-size: 32px; 
		padding: 10px 0;
	}
	.step-loading-icon {
		font-size: 24px; 
		padding: 10px 0;
	}
	.step-msg {
		font-size: 18px; 
		padding: 10px 0;
	}
	.auth-icon-con {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		.auth-icon-az {
			padding: 0 20px;
		}
		.auth-icon-arrow {
			padding: 0 20px;
		}
		.auth-icon-hytx {
			padding: 0 20px;
		}
	}
</style>
